import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import './Recrument.css';
import Recrumentimage from '../../../Components/Image/simpleAndEasyWay.svg';
import Hassle from '../../../Components/Image/Harssale.svg';
import Boost from '../../../Components/Image/BoostProductivity.svg';

import EmployeeeManagementimage from '../../../Components/Image/Group 1235@2x.png'
// import PolicyDocumentsimage from '../../../Components/Image/ils_05.png';
import PolicyDocumentsimage from '../../../Components/Image/Group 1235@2x.png';
import Symboll from '../../../Components/Image/Group 611@2x.png';
import './StartupFeature.css';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




export default function FullWidthGrid() {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <br />
                <br />
                <section>
                    <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                        <h1 style={{ fontWeight: "bold" }} className="empowering-style">Empowering Startups With complete HR Solution</h1>
                        <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                            HRVERSE will take care of your entire HR function in cost effective manner. It will not only allow Founders to focus on growth strategies and Client acquisition but also save time, money & energy
                        </p>
                    </div>
                    <br />
                </section>

                <section>
                    <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#707070" }}>
                        <h1> Startup <span style={{ fontWeight: "bold" }}> Features</span></h1>
                    </div>

                </section>
                <Container>
                    <Grid container spacing={2} className="reverse-boxes">
                        <Grid item sm={6} xs={12} lg={6} style={{ margin: "auto", textAlign: "left" }} className='revarse-grid'>
                            <img className="" src={Recrumentimage} alt="React Logo" style={{ width: "100%", height: "auto", objectFit: "contain", }} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} className='revarse-grid'>
                            <div>
                                <h1 className='hiringbold' style={{ textAlign: "left" }}>Simple And Easy   </h1>
                                <h5 className='' style={{ textAlign: "left", fontFamily: "lato", color: "#707070", fontSize: "18px", paddingLeft: "30px", opacity: "100%" }}> Attendance,leave management, And Single Click Payroll  </h5>
                                <p className='hiringparagraph' style={{ textAlign: "justify" }}> Simple and easy way for startups to track Attendance, manage leaves & single click salary generation for Payroll with HRVERSE. No need for startups to maintain spread sheets, record docs or manually calculate salaries. HRVERSE provides automated Attendance tracking, Leave management & error free Payroll with insights and data analysis to understand overall employee contribution towards your organizations. This also saves TIME, MONEY and ENERGY of Founders so that they can focus on business growth strategies.</p>
                            </div>
                        </Grid>


                        <Grid item xs={12} sm={6} lg={6} className='revarse-grid'>
                            <div>
                                <h1 className='hiringbold' style={{ lineHeight: "50px", textAlign: "left" }}>Hassle Free & cost-effective </h1>
                                <h5 className='' style={{ textAlign: "left", fontFamily: "lato", color: "#707070", fontSize: "18px", paddingLeft: "30px", opacity: "100%" }}>Employee Onboarding, Assets Management,Expenses Management,Startups Credits </h5>
                                <p className='hiringparagraph' style={{ textAlign: "justify" }}>HRVERSE empowers startups to effectively manage employee Onboarding, assets allocation and expenses management. Hassle free employee onboarding, proper asset allocation & return, expenses claim & reimbursement is time consuming process which can be efficiently implemented with HRVERSE in a cost-effective manner. This will result in saving organizations’ TIME, MONEY and ENERGY. Startups can also upload their startup certificate and avail credits from HRVERSE.</p>
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={12} lg={6} className='revarse-grid'>
                            <br />
                            <img className="" src={Hassle} alt="React Logo" style={{ width: "100%", height: "auto", objectFit: "contain", paddingTop: "5%" }} />
                        </Grid>


                        <Grid item sm={6} xs={12} lg={6} className='revarse-grid'>
                            <img className="" src={Boost} alt="React Logo" style={{ width: "100%", height: "auto", marginLeft: "-70PX", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} className='revarse-grid'>
                            <div style={{ textAlign: "justify" }}>
                                <h1 className='hiringbold' style={{ lineHeight: "50px" }}>Boost Productivity & Save Time  </h1>
                                <h5 className='' style={{ textAlign: "left", fontFamily: "lato", color: "#707070", fontSize: "18px", paddingLeft: "30px", opacity: "100%" }}>Employee Self Service, Document management, Exit Process</h5>
                                <p className='hiringparagraph' style={{ textAlign: "justify" }}>  HRVERSE completely avoids time waste for startups by providing Interactive dashboards for employees for leaves applications, approvals, instant salary slips & annual insights evading face to face interface for every instant. Similarly, automated exit process with entire task & assets handover along with end-to-end document management, HRVERSE ensures that startup don’t waste their time & quality manpower in these routine HR functions. Holistically it helps in saving TIME, MONEY and ENERGY for startups.</p>
                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </Box>




            {/* #############################################  */}



            {/* <Container>
                <br />
                <br />
                <br />
                <Box sx={{ flexGrow: 1 }}>
                    <section>
                        <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                            <h1 style={{ fontWeight: "bold" }} className="empowering-style">Empowering Startups With complete HR Solution</h1>
                            <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                HRVERSE will take care of your entire HR function in cost effective manner. It will not only allow Founders to focus on growth strategies and Client acquisition but also save time, money & energy
                            </p>
                        </div>
                        <br />
                    </section>
                    <section>
                        <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#707070" }}>
                            <h1> Startup <span style={{ fontWeight: "bold" }}> Features</span></h1>
                        </div>
                    </section>
                    <Grid container spacing={2} className="reverse-boxes">
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <img width={300} height="auto" src={Symboll} className="symboll-img" style={{ paddingTop: "180px" }} />
                            <img width={200} src={Symboll} className="symboll-imgas" />
                            <img className="" src={PolicyDocumentsimage} alt="React Logo" style={{ width: "100%", height: "90%", paddingTop: "40px", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <section>
                                <div>
                                    <h1 className='hiringbold'>Simple And Easy   </h1>
                                    <h5 className='' style={{ textAlign: "left", fontFamily: "lato", color: "#707070", fontSize: "18px", paddingLeft: "30px", opacity: "100%" }}> Attendance,leave management, And Single Click Payroll  </h5>
                                    <p className='hiringparagraph'> Simple and easy way for startups to track Attendance, manage leaves & single click salary generation for Payroll with HRVERSE. No need for startups to maintain spread sheets, record docs or manually calculate salaries. HRVERSE provides automated Attendance tracking, Leave management & error free Payroll with insights and data analysis to understand overall employee contribution towards your organizations. This also saves TIME, MONEY and ENERGY of Founders so that they can focus on business growth strategies.</p>
                                </div>
                            </section>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <br />
            <Container style={{ paddingTop: "25px" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} className="reverse-boxes">
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <section>
                                <div>
                                    <h1 className='hiringbold' style={{ lineHeight: "60px" }}>Hassle Free & cost-effective   </h1>
                                    <h5 className='' style={{ textAlign: "left", fontFamily: "lato", color: "#707070", fontSize: "18px", paddingLeft: "30px", opacity: "100%" }}> Employee Onboarding, Assets Management,Expenses Management,Startups Credits    </h5>
                                    <p className='hiringparagraph'>
                                        HRVERSE empowers startups to effectively manage employee Onboarding, assets allocation and expenses management. Hassle free employee onboarding, proper asset allocation & return, expenses claim & reimbursement is time consuming process which can be efficiently implemented with HRVERSE in a cost-effective manner. This will result in saving organizations’ TIME, MONEY and ENERGY. Startups can also upload their startup certificate and avail credits from HRVERSE.
                                    </p>
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <img width={300} height="auto" src={Symboll} className="symboll-img-emp" />
                            <img src={EmployeeeManagementimage} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                            <img width={300} height="auto" src={Symboll} className="symboll-img" style={{ paddingTop: "200px" }} />
                        </Grid>
                    </Grid>
                </Box></Container>
            <Container style={{ paddingTop: "25px" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <img width={300} height="auto" src={Symboll} className="symboll-img" />
                            <img src={PolicyDocumentsimage} alt="React Logo" style={{ objectFit: "contain", width: "100%", height: "100%", }} />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <section>
                                <div>
                                    <h1 className='hiringbold' style={{ lineHeight: "50px" }}>Boost Productivity & Save Time   </h1>
                                    <h5 className='' style={{ textAlign: "left", fontFamily: "lato", color: "#707070", fontSize: "18px", paddingLeft: "30px", opacity: "100%" }}>Employee Self Service, Document management, Exit Process </h5>

                                    <p className='hiringparagraph'>
                                        HRVERSE completely avoids time waste for startups by providing Interactive dashboards for employees for leaves applications, approvals, instant salary slips & annual insights evading face to face interface for every instant. Similarly, automated exit process with entire task & assets handover along with end-to-end document management, HRVERSE ensures that startup don’t waste their time & quality manpower in these routine HR functions. Holistically it helps in saving TIME, MONEY and ENERGY for startups. </p>
                                </div></section>
                        </Grid>
                    </Grid>
                </Box>
            </Container> */}



            <br />
        </>

    );
}



