import React from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Copyright from '../Copyright/Copyright/Copyright';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { NavLink } from 'react-router-dom'
import Login from '../Login/Login';



import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";



import './Footer.css';
import Logo from '../../Components/Image/HRVerse@2x.png'


// @@@@@@@@@@@@@@@@@@@@@@@@@
const Instagram = '../Blogs';
// Uri pass with  
const FacebookIcona = "Pricing";
// url pass with dom 
const Twitterurl = "/Recruitment";
let url = "home";





const Footer = () => {
    return (
        <>
            <Box>
                {/* <h1 style={{
                color: "green",
                textAlign: "center",
                marginTop: "-50px"
            }}>
                GeeksforGeeks: A Computer Science Portal for Geeks
            </h1> */}
                <Container>
                    <Row>
                        <Column>
                            <Heading className="headingfont">   </Heading>
                            {/* <Link to="/home"> */}
                            <img src={Logo} className='logocsss' />
                            {/* </Link> */}

                            {/* <FooterLink to="#">Aim</FooterLink> */}
                            {/* <FooterLink to="#">Vision</FooterLink> */}
                            {/* <FooterLink to="#">Testimonials</FooterLink> */}

                        </Column>
                        <Column>
                            <Heading className="headingfont">Useful links</Heading>
                            <FooterLink className="FooterLinkfont"><Link to="/"> Home </Link></FooterLink>
                            <FooterLink className="FooterLinkfont" to="#">About Us</FooterLink>
                            <FooterLink className="FooterLinkfont"><Link to="/Startup" >  Startups</Link>  </FooterLink>
                        </Column>
                        <Column>
                            <Heading className="headingfont displyanone"> </Heading>
                            <FooterLink className="FooterLinkfont displyanone"  > <Link to="/Pricing">Pricing  </Link> </FooterLink>
                            <FooterLink className="FooterLinkfont"><Link to="/Recruitment">Features</Link> </FooterLink>
                            <FooterLink className="FooterLinkfont"> <Link to="/Blogs"> Blogs </Link>  </FooterLink>
                        </Column>
                        <Column>
                            <Heading className="headingfont">Contact Us</Heading>
                            <FooterLink className="FooterLinkfont" to="mailto:help@hrverse.com" >connect@hrverse.com</FooterLink>
                            <FooterLink className="FooterLinkfont" to="#" >
                                <a href="#"> <FacebookIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                                <a href="#"> <InstagramIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                                <a href="#"> <YouTubeIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                                <a href="#"> <LinkedInIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                            </FooterLink>
                        </Column>
                        <Column>
                            <Heading className="headingfont"> Newsletter</Heading>
                            <FooterLink className="FooterLinkfont" >
                                {/* <form>
                                    <div class="displyflex" style={{ backgroundcolor: "white" }}>
                                        <input className="forminline" type="text" placeholder=" Enter your email" name="" required />
                                        <input style={{ textAlign: "center" }} className="subscription" type="submit" value="Subscribe" />
                                    </div>
                                </form> */}

                                {/* ################ */}
                                <form class="form-inline" action="/">
                                    <input className="inputform" type="email" id="email" placeholder="Enter your email " name="email" />
                                    &nbsp;
                                    <button className="buttonform" type="submit">Subscribe</button>
                                </form>
                                {/* @@@@@@@@@@@@@@ */}
                            </FooterLink>
                        </Column>
                    </Row>
                </Container>
            </Box>
        </>

    );
};
export default Footer;




