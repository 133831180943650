import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import attendancelsymboll from '../../../Components/Image/Group 6330@2x.png';
import pricingcardsymboll from '../../../Components/Image/Group 6330@2x.png';
import { padding } from '@mui/system';
import './Attendancemana.css';
import ReadmeMore from "./FAQ/ReadmeMore";
import ReadmeSecond from "./FAQ/ReadmeSecond";
import Readmethird from "./FAQ/Readmethird";




export default function FullWidthGrid() {
    return (
        <>


            <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "50px" }}>

                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <h1 className='attandanceheading'>  Attendance management FAQs.</h1>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <br />
                            <div style={{ display: "flex", padding: "10px" }}>
                                <img className='attandancesymbol' src={pricingcardsymboll} width={40} style={{ position: "absolute" }} />
                                <h1 className='attandanceheadingright' style={{
                                    marginLeft: "50px"

                                }}>Employee Document Management  </h1>
                            </div>

                            <div>
                                <ReadmeMore />

                            </div>


                            <div style={{ display: "flex", padding: "10px" }}>
                                <img className='attandancesymbol' src={pricingcardsymboll} width={40} style={{ position: "absolute" }} />
                                <h1 className='attandanceheadingright' style={{
                                    marginLeft: "50px"

                                }}>Employee Self Service </h1>



                            </div>
                            <div>
                                <ReadmeSecond />

                                {/* <p className='attandancetagline'>   HRVERSE aims at making employees self-sufficient by providing interactive dashboards to avoid waste of efforts and time. Employees can access their dedicated dashboards to apply for leaves, short leaves, half days and seek hierarchy wise approvals on the dashboards. Simultaneously employees can instantly download salary slips, view profile, view yearly leave calendar, leave records, balance leaves, encashment if any along with assets assigned.   </p> */}
                            </div>


                            <div style={{ display: "flex", padding: "10px" }}>
                                <img className='attandancesymbol' src={pricingcardsymboll} width={40} style={{ position: "absolute" }} />
                                <h1 className='attandanceheadingright' style={{
                                    marginLeft: "50px"

                                }}>Exit Process</h1>
                            </div>
                            <div>

                                <Readmethird />
                                {/* <p className='attandancetagline'>Exit Process  Smooth and transparent exit process with HRVERSE helps organizations to maintain their goodwill and brand value. HRVERSE offers seamless digitized exit formalities right from employee resignation to full & final settlements. Once Employees resign, the complete process is automated enabling resignation approvals, task handover, asset return & exit interviews. Organizations can also provide relevant documents to employee such as relieving letter, experience letter, no due letter along with limiting the access. HR Department can holistically refer to the entire data and insights for the exit employees. </p> */}
                            </div>



                        </Grid>

                    </Grid>
                </Container>
            </Box>

        </>
    );
}
