import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import './Startforfree.css';
import Startforfreecontent from './Startforfreecontent';
import Symboll from '../../../Components/Image/sidecutimage.png';

import { Link } from "react-router-dom";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>


            <Container className='Containerstart' >
                <img width={140} height="auto" src={Symboll} className="symboll-img-free" />

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid className='already-a-Member' item xs={10} md={6} style={{ textAlign: "center" }} >
                            <br className='display-none' />
                            <Startforfreecontent />
                            {/* <h1 className='StartFont' > Start For Free.</h1> */}

                        </Grid>
                        <Grid className='already-a-Member display-none ' item xs={1} md={1} style={{ textAlign: "center", paddingTop: '50px' }}>
                            <div className="vl"></div>
                        </Grid>
                        <Grid className='already-a-Member ftgrdetg   ' item xs={10} md={5} style={{ textAlign: "center", }}>

                            <br className='display-none' />
                            <br className='display-none' />
                            <br className='display-none' />
                            <br className='display-none' />

                            <span className='already  already-responsive' style={{ color: "#606062", }}>  Already a Member ? </span><Link to="/Login" style={{ color: "#606062", fontSize: "22px" }}>Sign in</Link>
                        </Grid>

                    </Grid>
                </Box>
            </Container>

        </>
    );
}
