import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import './ApplyForCredits.css';
import Card from '@mui/material/Card';

import Grid from '@mui/material/Grid';

// import pricingcardsymboll from '../../../Components/Image'

import pricingcardsymboll from '../../../Components/Image/sidecutimage.png';
import pricingcardsymbolltwo from '../../../Components/Image/Group 6330@2x.png';
import { Link } from 'react-router-dom';
import { color } from '@mui/system';
import CompanyDetailsForm from '../CompanyDetails/CompanyDetailsForm';

export default function FixedContainer() {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container >
                {/* <br />
                <br />
                <br /> */}
                {/* <br /> */}
                {/* <Box className='applyforcredit' sx={{ bgcolor: '#e7f3fe', height: '100vh', borderRadius: "10px" }} > */}
                <Box className='applyforcredit' >

                    <img src={pricingcardsymboll} style={{ maxWidth: "100%", height: "100px", objectFit: "contain" }} />
                    <div style={{ position: "absolute", textAlign: "center", marginLeft: "30%", marginTop: "-70px" }}>
                        <img src={pricingcardsymbolltwo} className="applyfor-img" />
                        <h1 className=' startuphrad'  >Apply For Credits</h1>
                        <h1 className=' startupparagraph'  >Enter the required details below </h1>
                    </div>
                    <Card
                        style={{ marginLeft: "10%", marginRight: "10%", marginTop: "30px", position: "relative" }}>
                        <CompanyDetailsForm />


                    </Card>






                </Box>
            </Container>
        </React.Fragment>
    );
}






