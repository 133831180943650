import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Startupimage from "../../Components/Image/Group 856@2x.png"
// import './StartupSection.css';
import Button from '@mui/material/Button';
// import Dialogform from '../Dialog/Dialogform';
import Dialogform from '../../Pages/Home/Dialog/Dialogform';
// import './BackgroundSection.css';
import './Backgroundsections.css';

{/* <img src={Startupimage} style={{ width: "100%", height: "auto", objectFit: "contain" }} /> */ }

export default function SimpleContainer() {
    return (
        <React.Fragment>
            <CssBaseline />


            <br />
            <br />
            <div className='demo-body'>
                <h1>ewrtfetgredgt</h1>
            </div>

            <div class="container-img">
                {/* <img src={Startupimage} alt="Snow" style="width:100%;"/> */}
                <img src={Startupimage} style={{ width: "100%", }} />

                {/* <div class="fist-line">Bottom Left</div>
                <div class="second-line">Top Left</div>
                <div class="top-right">Top Right</div>
                <div class="bottom-right">Bottom Right</div> */}
                <div class="centere-line" >
                    <span className='fist-line'>SAVE TIME</span>
                    <span className='your-line'>MONEY</span>
                    <h1 style={{ paddingLeft: "50px" }} className="second-line">& ENERGY</h1>

                    <h6 className='third-line'>Cost Effective Startup Plans</h6>

                    <div className='fourth-line' style={{ paddingTop: "20px" }}><Dialogform /></div>



                </div>
            </div>






        </React.Fragment>
    );
}



