import React from 'react';

//importing typewriter-effect
import Typewriter from "typewriter-effect";
import './TypingEffect.css';

function App() {
    return (
        <div className='Typewriter' >
            <Typewriter
                onInit={(typewriter) => {

                    typewriter

                        .typeString("Your HR needs Simplified…")

                        .pauseFor(1000)
                        .deleteAll()
                        .typeString(" Cost effective HR Solution...")
                        .start();
                }}
            />
        </div>
    );
}

export default App;
