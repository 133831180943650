import React, { useState } from 'react';
// import { FaArrowCircleUp } from 'react-icons/fa';
import AcUnitIcon from '@mui/icons-material/AcUnit';
// import { Button } from './Styles';
import Button from '@mui/material/Button';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
                in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (


        <button onClick={scrollToTop} style={{ position: "fixed" }}>
            {/* <AcUnitIcon onClick={scrollToTop} */}
            {/* // style={{ display: visible ? 'inline' : 'none' }}  */}

            {/* /> */}
            Book Live Demo

        </button>
    );
}

export default ScrollButton;
