import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Recruitment.css';
// import EmployeeeManagementimage from '../../../Components/Image/ils_05@2x.png'
// import PolicyDocumentsimage from '../../../Components/Image/ils_05.png';
import PolicyDocumentsimage from '../../../Components/Image/ils_05@2x.png';
import Servicescard from '../../Services/Servicescard/Servicescard';
import Startforfree from '../../Home/Startforfree/Startforfree';
import Customer from "../../Home/Customer/Customer";
import CustomerResponsive from "../../Home/Customer/CustomerResponsive";
import Featurestwo from './Featurestwo/Featurestwo';
// ########### imag svg Payroll 
import EmployeeeManagementimage from '../../../Components/Image/Attendance & Leaves.svg';
import Payrollimg from '../../../Components/Image/Salary & Payroll (1).svg';
import LeaveImage from '../../../Components/Image/Onboarding.svg';




import Footer from '../../Footer/Footer';
import Copyright from '../../Copyright/Copyright/Copyright';
import Sidebar from '../../../Components/Sidebar';
import Symboll from '../../../Components/Image/Group 611@2x.png';
import Attendancemana from "./Attendancemana";
import Positionfix from '../../../Demopages/positionfix/Positionfix';
import FooterMobile from '../../FooterMobile/FooterMobile';
import FeaturesCardScroll from '../../../Pages/Services/Servicescard/FeaturesCardScroll/FeaturesCardScroll';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Sidebar />
            <div style={{ paddingTop: "130px" }} id="first_feature">

                <Container style={{ paddingTop: "25px" }} >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} className="reverse-boxes">

                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                                <section>
                                    <div>
                                        {/* <p className='hiringparagraph'>The complete attendance management system</p> */}
                                        <h1 className='hiringboldtop'>Attendance Management</h1>
                                        <p className='hiringparagraph'> Tracking employee attendance is of utmost importance; it gives an idea about overall employee presence and absence in the organization. With HRVERSE you can track employee entry & exit time, work duration, break time and physical attendance. HRVERSE also provides interactive dashboards for employees to understand their attendance track record along with the leaves availed. HRVERSE can be integrated with biometric device to understand employee punctuality and accurately track employee attendance.</p>
                                    </div></section>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                                {/* <Item> */}

                                <br />
                                <img width={300} height="auto" src={Symboll} className="symboll-img-recuriment" />


                                <img src={EmployeeeManagementimage} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />


                                {/* </Item> */}
                            </Grid>

                        </Grid>
                    </Box></Container>
                <Container style={{ paddingTop: "25px" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} className="reverse-boxes">
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                                <img width={350} height="auto" src={Symboll} className="symboll-img-recument-2" />
                                <br />
                                <br />
                                <img src={Payrollimg} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain", textAlign: "left" }} />

                            </Grid>

                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6} >
                                <section>
                                    <div>
                                        <h1 className='hiringboldtop'>Payroll</h1>
                                        <p className='hiringparagraph'>  With HRVERSE Payroll is no more a complex and time-consuming process. HRVERSE is developed with an objective to save your organization’s time, money & energy by enabling One click salary generation. Attendance & Leave records are integrated with HRVERSE to ensure  timely & error-free payroll. HRVERSE provides dedicated interface for each employee for instant access to pay slips, leave insights and ensures tax compliance as per employee Earnings and Investments.

                                        </p>
                                    </div>
                                </section>


                            </Grid>
                        </Grid>

                    </Box>
                </Container>
                <Container  >
                    <Box sx={{ flexGrow: 1 }}>
                        <br /><br />  <br /><br />

                        <Grid container spacing={2} className="reverse-boxes">
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                                <section>
                                    <div>
                                        <h1 className='hiringboldtop'>Leave Management</h1>
                                        <p className='hiringparagraph'>  HRVERSE offers end-to-end seamless leave management for your employees. Right from Leave applications, leave approvals, half days, short leaves, total leaves, balance leaves to leaves encashment and leaves carry forwards. By analyzing the data HRVERSE provides leave insights, summary reports and overall employee leave status for HR department to decide next plan of action for respective employee. Dedicated dashboards are also provided to employees so that they can track their real-time leave status.   </p>
                                    </div></section>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>

                                <img width={300} height="auto" src={Symboll} className="symboll-img-recuriment" />

                                <img src={EmployeeeManagementimage} alt="React Logo" style={{ objectFit: "contain", width: "100%", height: "90%", }} />

                            </Grid>


                        </Grid>
                    </Box>
                </Container>
                <br />





                <div className='Servicescard'>


                    <h1 className='featuresfont' style={{ textAlign: "center" }} >Features</h1>
                    <FeaturesCardScroll />

                    {/* <Servicescard /> */}
                </div>

                <div>
                    <br />
                    <br />

                    <Featurestwo />


                </div>


                <div>
                    <br />
                    <br />
                    <br />

                    {/* <Attendancemana /> */}

                    <Positionfix />


                </div>




                <br />
                <br />

                {/* <div style={{ paddingRight: "200px", paddingLeft: "200px" }}>  <Startforfree /> </div> */}
                {/* <div className='Startforfreecontainer'  >  <Startforfree /> </div> */}
                {/* <Positionfix /> */}

                <Attendancemana />

                <br />



                {/* <Container className=''>
                    <hr className='horizontalline' />

                </Container> */}


                <div className='display-none-form'>

                    <Customer />
                </div>
                <div className='display-show'>
                    <CustomerResponsive />
                </div>





                {/* <Container className=''> */}
                {/* <hr className='horizontalline2' /> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <div className='display-none-form'>
                    <Footer />
                    {/* <Container className=''> */}
                    <hr className='horizontallinebottom' />
                    {/* </Container> */}
                    <Copyright />
                </div>
                <div className='display-show'>
                    <FooterMobile />
                </div>


            </div>
        </>

    );
}
